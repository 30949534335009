<template lang="">
    <div>

        <v-container>

            <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-subheader class="text-h5 black--text">
                           Georreferenciación de Proyectos de Cooperación para el Desarrollo
                        </v-subheader>
                
                        <v-divider class="mt-0 pt-0"></v-divider>
                </v-col>
            </v-row>

            <v-row>
                <!--inicio:: mapa-->
                <v-col cols="12" md="12" sm="12" xs="12">
                    <div v-if="mostrarMapaLoading">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-skeleton-loader
                                min-height="600px"
                                type="article"
                                >
                                </v-skeleton-loader>
                        </v-col>
                        </v-row>
                    </div>

                    <GmapMap v-if="!mostrarMapaLoading"
                        :center="centerMap"
                        :zoom="7"
                        ref="map"
                        id="map"
                        style="width: 100%; height: 800px"
                    >

                        

                        <GmapMarker
                            v-for="(m, index) in marcadores"
                            :key="index"
                            :position="m.position"
                            :clickable="true"
                            :draggable="false"
                            @click="
                                center = m.position;
                                openGmapInfo(index, m);
                            "
                            :icon="m.position.tipoCooperacionId===1 ? iconoCFNR : m.position.tipoCooperacionId===2 ? iconoCTNR : iconoCENR"
                        >

                        

                        <!-- <gmap-info-window
                            :position="infoWindow.position"
                            :opened="infoWindow.open[index]"
                            @closeclick="closeInfoWindow(index)"
                        >
                            
                            <div v-html="infoWindow.template">
                            </div>
                            
                        </gmap-info-window>  -->
                        </GmapMarker>
                        <gmap-info-window
                            :options="infoOptions"
                            :position="infoWindowPosition"
                            :opened="infoWindowOpen"
                            @closeclick="infoWindowOpen=false"
                        >
                            <!--<button @click="closeInfoWindow(index)">Close</button>-->
                            <div v-html="infoWindow.template">
                            </div>
                            
                        </gmap-info-window> 
                    </GmapMap>
                </v-col>
                <!--fin:: mapa-->


                <v-col cols="12" md="12" sm="12" xs="12">
                    <v-card>
                        <v-card-title>
                            <v-row>
                                <v-col cols="12">
                                    Filtros de búsqueda

                                    <v-btn
                                        small
                                        color="red lighten-1"
                                        class="white--text mb-2 btn-sm  float-right"
                                        @click="eliminarFiltros();"
                                    >
                                    <v-icon>mdi-filter-minus-outline</v-icon>
                                        Eliminar filtros
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>

                            <v-row class="mt-4">
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="modalidadCooperacionSeleccionada"
                                        :items="modalidadesCooperacion"
                                        :loading="false"
                                        dense
                                        :clearable="true"
                                        filled
                                        @change="definirFiltro()"
                                        label="Modalidad de cooperación"
                                        item-text="modalidad"
                                        item-value="id"
                                        :no-data-text="
                                            modalidadesCooperacion != null
                                            ? 'Seleccione una modalidad de cooperación'
                                            : 'No se han encontrado modalidades de cooperación'
                                        "
                                       
                                        menu-props="auto"
                                    ></v-select>

                                    
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="estadoSeleccionado"
                                        :items="esatadosProyectos"
                                        :loading="ddEstadosProyectos"
                                        dense
                                        :clearable="true"
                                        filled
                                        @change="validarEstadoProyecto; definirFiltro()"
                                        label="Estado del proyecto"
                                        item-text="nombreEstado"
                                        item-value="id"
                                        :no-data-text="
                                            esatadosProyectos != null
                                            ? 'Seleccione un estado para los proyectos'
                                            : 'No se han encontrado estados de proyectos'
                                        "
                                       
                                        menu-props="auto"
                                    ></v-select>

                                    
                                </v-col>

                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-text-field
                                        color="blue-grey lighten-2"
                                        autocomplete="off"
                                        class=""
                                        dense
                                        filled
                                        v-model="palabraClave"
                                        @input="definirFiltro()"
                                        label="Nombre del proyecto, código Sicoopera"
                                        maxlength="50"
                                    ></v-text-field>
                                </v-col>


                                <!-- <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="tipoCooperacionSeleccionada"
                                        :items="tiposCooperacion"
                                        :loading="false"
                                        dense
                                        :clearable="true"
                                        filled
                                        label="Tipo de Cooperación"
                                        item-text="nombreTipoCooperacion"
                                        item-value="id"
                                        :no-data-text="
                                        tiposCooperacion != null
                                            ? 'Seleccione un tipo de cooperación'
                                            : 'No se han encontrado tipos de cooperacion'
                                        "
                                       
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>
 -->


                                

                            </v-row>

                            <v-row class="mt-4">
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        @change="obtenerMunicipiosPorDepartamento(departamentoSeleccionado); definirFiltro()"
                                        v-model="departamentoSeleccionado"
                                        :items="departamentos"
                                        :loading="departamentosLoading"
                                        dense
                                        filled
                                        label="Departamento"
                                        item-text="departamento"
                                        item-value="id"
                                        :no-data-text="
                                            departamentos != null
                                            ? 'Seleccione un departamento'
                                            : 'No se han encontrado departamentos'
                                        "
                                       
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>


                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        @change="definirFiltro()"
                                        v-model="municipioSeleccionado"
                                        :items="municipios"
                                        :loading="municipiosLoading"
                                        dense
                                        filled
                                        label="Municipio"
                                        item-text="municipio"
                                        item-value="id"
                                        :no-data-text="
                                            municipios != null
                                            ? 'Seleccione un municipio'
                                            : 'No se han encontrado municipios'
                                        "
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>


                            </v-row>

                            <v-row>
                            
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-autocomplete
                                    v-model="entidadSeleccionada"
                                    :items="instituciones"
                                    class=""
                                    dense
                                    filled
                                    :loading="ddInstitucionesLoading"
                                    label="Institución"
                                    :item-text="item => item.sigla ? `${item.nombreInstitucion} (${item.sigla})` : item.nombreInstitucion"
                                    item-value="id"
                                    @change="obtenerUnidadesEjecutorasPorEntidad(entidadSeleccionada); definirFiltro()"
                                    
                                    ></v-autocomplete>
                                </v-col>
                        
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-autocomplete
                                        v-model="unidadEjecutoraSeleccionada"
                                        @change="definirFiltro()"
                                        :items="unidadesEjecutoras"
                                        class=""
                                        dense
                                        filled
                                        :loading="unidadesEjecutorasLoading"
                                        label="Unidad ejecutora"
                                        item-text="unidadEjecutora"
                                        item-value="id"
                                       
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="sectorBeneficiadoSeleccionado"
                                        @change="definirFiltro()"
                                        :items="sectoresBeneficiados"
                                        :loading="sectoresBeneficiadosLoading"
                                        dense
                                        filled
                                        label="Sector beneficiado"
                                        item-text="sector"
                                        item-value="id"
                                        :no-data-text="
                                            sectoresBeneficiados > 0
                                            ? 'Seleccione un sector beneficiado'
                                            : 'No se han encontrado sectores beneficiados'
                                        "
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>
                           
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="tipoCooperacionSeleccionada"
                                        @change="definirFiltro()"
                                        :items="tiposCooperacion"
                                        :loading="false"
                                        dense
                                        filled
                                        class=""
                                        label="Tipo de cooperación"
                                        item-text="nombreTipoCooperacion"
                                        item-value="id"
                                        :no-data-text="
                                        tiposCooperacion != null
                                            ? 'Seleccione un tipo de cooperación'
                                            : 'No se han encontrado tipos de cooperación'
                                        "
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="pndSeleccionado"
                                        @change="definirFiltro()"
                                        :items="pnds"
                                        :loading="ddPndLoading"
                                        dense
                                        class=""
                                        filled
                                        label="Prioridad Nacional de Desarrollo"
                                        item-text="nombrePND"
                                        item-value="id"
                                        :no-data-text="
                                            pnds != null
                                            ? 'Seleccione Prioridad Nacional de Desarrollo'
                                            : 'No se han encontrado Prioridades Nacionales de Desarrollo'
                                        "
                                        menu-props="auto"
                                        >
                                    </v-select>
                                </v-col>
                            
                                <!--inicio:: ods -->
                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="odsSeleccionado"
                                        @change="definirFiltro()"
                                        :items="ods"
                                        :loading="ddOdsLoading"
                                        dense
                                        class=""
                                        filled
                                        label="Objetivo de Desarrollo Sostenible"
                                        item-text="nombreODS"
                                        item-value="id"
                                        :no-data-text="
                                            pnds != null
                                            ? 'Seleccione Objetivo de Desarrollo Sostenible'
                                            : 'No se han encontrado Objetivos de Desarrollo Sostenible'
                                        "
                                        menu-props="auto"
                                       
                                        >
                                    </v-select>
                                </v-col>
                            </v-row>
                            
                            <!--inicio:: pgg -->
                            <!-- <v-row>
                                
                                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="pggSeleccionada"
                                        :items="pggs"
                                        :loading="ddPggLoading"
                                        dense
                                        class=""
                                        filled
                                        label="Política General de Gobierno"
                                        item-text="nombrePGG"
                                        item-value="id"
                                        :no-data-text="
                                            pggs != null
                                            ? 'Seleccione Política General de Gobierno'
                                            : 'No se han encontrado Políticas Generales de Gobierno'
                                        "
                                        menu-props="auto"
                                        
                                        >
                                    </v-select>
                                </v-col>
                            </v-row> -->
                            
                            <v-row>
                                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    <v-select
                                        v-model="tipoFuenteCooperanteSeleccionada"
                                        @change="definirFiltro()"
                                        :items="tiposFuenteCooperante"
                                        :loading="ddTipoFuenteCooperanteLoading"
                                        dense
                                        filled
                                        class=""
                                        label="Tipo de fuente cooperante"
                                        item-text="nombreTipoFuente"
                                        item-value="id"
                                        :no-data-text="
                                            tiposFuenteCooperante != null
                                            ? 'Selecciona un tipo de fuente cooperante'
                                            : 'No se han encontrado tipos de fuente cooperante'
                                        "
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>
                            
                            </v-row>

                            <v-row>

                                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada == 1">
                                    <v-select
                                        v-model="fuenteBilateralSeleccionada"
                                        :items="fuentesBilaterales"
                                        :loading="ddFuentesBilateralesLoading"
                                        :required="tipoFuenteCooperanteSeleccionada == 1"
                                        dense
                                        class=""
                                        filled
                                        label="País Fuente Bilateral"
                                        item-text="pais"
                                        item-value="id"
                                        :no-data-text="
                                            fuentesBilaterales != null
                                            ? 'Selecciona un país'
                                            : 'No se han encontrado países'
                                        "
                                        @change="obtenerAgenciasPorFuenteBilateral(fuenteBilateralSeleccionada); agenciaSeleccionada={}; definirFiltro()"
                                        menu-props="auto"
                                        ></v-select>
                                </v-col>

                                 <!-- inicio:: agencias -->
                                <v-col cols="12"  md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada == 1">
                                    <v-select
                                        v-model="agenciaSeleccionada"
                                        @change="definirFiltro()"
                                        :items="agencias"
                                        :loading="ddAgenciasLoading"
                                        :clearable="true"
                                        dense
                                        filled
                                        label="Seleccione Agencia"
                                        item-text="nombreAgencia"
                                        item-value="id"
                                        :no-data-text="
                                            agencias > 0
                                            ? 'Selecciona una agencia'
                                            : 'No se han encontrado agencias'
                                        "
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>
                                <!-- fin:: agencias -->

                                 <v-col cols="12"  md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada == 2">
                                    <v-select
                                        v-model="fuenteMultilateralSeleccionada"
                                        :items="fuentesMultilaterales"
                                        :loading="ddFuentesMultilateralesLoading"
                                        class=""
                                        dense
                                        filled
                                        label="Seleccione fuente multilateral"
                                        item-text="nombreFuenteMultilateral"
                                        item-value="id"
                                        :no-data-text="
                                            fuentesMultilaterales > 0
                                            ? 'Seleccione una fuente multilateral'
                                            : 'No se han encontrado fuentes multilaterales'
                                        "
                                        menu-props="auto"
                                        @change="obtenerAgenciasMultilaterales(fuenteMultilateralSeleccionada); agenciaMultilateralSeleccionada={}; definirFiltro()"
                                    ></v-select>
                                </v-col>

                                 <!-- inicio:: agencias -->
                                <v-col cols="12"  md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada == 2">
                                    <v-select
                                        v-model="agenciaMultilateralSeleccionada"
                                        @change="definirFiltro()"
                                        :items="agenciasMultilaterales"
                                        :clearable="true"
                                        :loading="agenciasMultilateralesLoading"
                                        dense
                                        filled
                                        label="Seleccione Agencia Multilateral"
                                        item-text="agencia"
                                        item-value="id"
                                        return-object
                                        :no-data-text="
                                        agenciasMultilaterales > 0
                                            ? 'Selecciona una agencia'
                                            : 'No se han encontrado agencias para la fuente seleccionada'
                                        "
                                        menu-props="auto"
                                    ></v-select>
                                </v-col>
                                <!-- fin:: agencias -->

                            </v-row>

                            



                            <v-row>
                                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                     <v-btn
                                        color="light-blue-502"
                                        class="white--text mb-2 float-right"
                                        type="submit"
                                        :elevation="0"
                                        :loading="btnBuscarProyectosLoading"
                                        :disabled="btnBuscarProyectosLoading"
                                        @click="obtenerProyectos();"
                                        >
                                        <v-icon>mdi-magnify</v-icon>Buscar proyectos
                                    </v-btn>
                                </v-col>
                            </v-row>


                        </v-card-text>
                    </v-card>

                </v-col>


                <v-col cols="12" md="12" sm="12" xs="12" class="mb-8">
                    <v-card
                        class="mx-auto"
                        max-width="100%"
                    >
                        <v-toolbar
                            color="light-blue-502"
                            dark
                            >
                            <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>-->
                            <v-toolbar-title>Proyectos encontrados {{cantProyectosEncontrados}}</v-toolbar-title>

                            <v-spacer></v-spacer>

                        <!-- <v-btn icon>
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>

                        <v-btn icon>
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn> -->
                        </v-toolbar>

                        <v-navigation-drawer
                            v-model="drawer"
                            absolute
                            temporary
                            >

                            
                            
                        </v-navigation-drawer>

                         <div v-if="btnBuscarProyectosLoading">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-skeleton-loader
                                    min-height="600px"
                                    type="article, article, article"
                                    >
                                    </v-skeleton-loader>
                            </v-col>
                            </v-row>
                        </div>

                        <v-list two-line style="height:400px; overflow-y:auto" v-if="!btnBuscarProyectosLoading">
                                <v-list-item-group
                                    
                                    active-class="blue--text"
                                >

                                    <v-list-item  @click="setAllMarcadores()" v-if="proyectos.length > 1">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6">Todos los proyectos</v-list-item-title>
                                                 <v-list-item-subtitle class="text-subtitle-1">Visualizar todos los proyectos filtrados</v-list-item-subtitle>
                                            </v-list-item-content>

                                         
                                        </template>
                                    </v-list-item>
                                    <v-divider></v-divider>


                                
                                    <template v-for="(item, index) in proyectos">
                                        <v-list-item :key="item.codigoProyecto" @click="setMarcadores(item, item.marcadoresDoc)">
                                            <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6" v-text="item.codigo"></v-list-item-title>

                                                <!-- <v-list-item-subtitle
                                                class="text--primary"
                                                v-text="item.headline"
                                                ></v-list-item-subtitle>
                        -->
                                                <v-list-item-subtitle class="text-subtitle-1" v-text="item.nombreProyecto"></v-list-item-subtitle>
                                            </v-list-item-content>

                                            <!-- <v-list-item-action>
                                                <v-list-item-action-text v-text="item.action"></v-list-item-action-text>

                                                <v-icon
                                                v-if="!active"
                                                color="grey lighten-1"
                                                >
                                                mdi-star-outline
                                                </v-icon>

                                                <v-icon
                                                v-else
                                                color="yellow darken-3"
                                                >
                                                mdi-star
                                                </v-icon>
                                            </v-list-item-action> -->
                                            </template>
                                        </v-list-item>

                                        <v-divider
                                            v-if="index < proyectos.length - 1"
                                            :key="index"
                                        ></v-divider>
                                    </template>
                                </v-list-item-group>
                            </v-list>
                    </v-card>

                </v-col>

                

            </v-row>

            
        </v-container>
    </div>
</template>
<script>
import {OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";
import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";
import {  OBTENER_PROYECTOS_GEORREFERENCIACION} from "@/core/services/store/reportes/proyectossicoopera/proyectosicoopera.module";
export default {
    name: "ReporteGeorreferenciacionGeneral",
    data() {
        return {
            proyectos: [],
            marcadoresProyecto: [],
            marcadores: [],
            selectedItem: [0],
            modalidadesCooperacion:[],
            modalidadCooperacionSeleccionada:0,
            esatadosProyectos: [],
            ddEstadosProyectos: {},
            estadoSeleccionado: 0,
            palabraClave: "",
           /*  tiposCooperacion:[],
            tipoCooperacionSeleccionada:0, */
            departamentosLoading: false,
            departamentos: [],
            departamentoSeleccionado:0,
            municipios: [],
            municipiosLoading: false,
            municipioSeleccionado: 0,
            entidadSeleccionada:0,
            instituciones: [],
            unidadesEjecutorasLoading: false,
            unidadesEjecutoras: [],
            unidadEjecutoraSeleccionada:0,
            cantProyectosEncontrados: 0,
            btnBuscarProyectosLoading:false,
            ddInstitucionesLoading:false,
            sectorBeneficiadoSeleccionado: 0,
            sectoresBeneficiados: [],
            sectoresBeneficiadosLoading: false,
            tiposCooperacion: [],
            tipoCooperacionSeleccionada:0,
            ddTiposCooperacionLoading: false,
            tiposFuenteCooperante: [],
            ddTipoFuenteCooperanteLoading: false,
            tipoFuenteCooperanteSeleccionada: 0,
            fuenteBilateralSeleccionada:0,
            fuentesBilaterales: [],
            ddFuentesBilateralesLoading: false,
            agencias: [],
            ddAgenciasLoading: false,
            agenciaSeleccionada: 0,
            fuenteMultilateralSeleccionada: 0,
            fuentesMultilaterales: [],
            ddFuentesMultilateralesLoading: false,
            agenciasMultilaterales:[],
            agenciaMultilateralSeleccionada:0,
            agenciasMultilateralesLoading:false,
            ddPndLoading: false,
            pnds: [],
            pndSeleccionado:0,
            odsSeleccionado: 0,
            ods: [],
            ddOdsLoading:false,
            pggs:[],
            pggSeleccionada: 0,
            ddPggLoading: false,
            noFilter: false,
            drawer: false,
            mostrarMapaLoading: false,
            items: [
                { text: 'Real-Time', icon: 'mdi-clock' },
                { text: 'Audience', icon: 'mdi-account' },
                { text: 'Conversions', icon: 'mdi-flag' },
            ],
            centerMap: { lat: 16.062775, lng: -89.3520961 },
            infoWindow: {
                open: [],
                position: { lat: 0, lng: 0 },
                template: "",
            },
            iconoCFNR: "media/logos/marcadores/marcador_1.png",
            iconoCTNR: "media/logos/marcadores/marcador_2.png",
            iconoCENR: "media/logos/marcadores/marcador_3.png",

            infoWindowPosition: null,
            infoWindowOpen:false,
            currentMidx: null,
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                width: 0,
                height: -35
                }
            },
        }
    },

    methods: {
        //Obtener las modalidades de cooperación
        obtenerModalidadesCooperacion(){
            this.modalidadesCooperacion.push({id: 1, modalidad: "Cooperación Norte Sur (CNS)"})
        },

        //Obtener estados 
        async obtenerEstadosProyecto() {
            this.esatadosProyectos = [];
            this.ddEstadosProyectos = true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `EstadosProyecto/all/1`})
            .then(res => {
            if (res.status === 200) {
                this.esatadosProyectos = res.data;
            }
            this.ddEstadosProyectos = false;
            })
            .catch(() => {
                this.esatadosProyectos = [];
                this.ddEstadosProyectos = false;
            });
        },

         //Tipos de cooperacion
         /* async obtenerTiposCooperacion() {
            this.tiposCooperacion = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposCooperacion/all/1'})
            .then(res => {
            if (res.status === 200) {
                this.tiposCooperacion = res.data;
            }
            })
            .catch(() => {
                this.tiposCooperacion = [];
            });
        }, */

        //Obtener departamentos
        async obtenerDepartamentos(){
            this.departamentosLoading=true;
           
            this.departamentos = [];
            //this.departamentosActividad = [];
            await this.$store
            .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.departamentos = res.data;
                    //this.departamentosActividad = res.data;

                }
                this.departamentosLoading=false;
               // this.departamentosActividadLoading=false;
            })
            .catch(() => {
                this.departamentos = [];
                //this.departamentosActividad = [];
                this.departamentosLoading=false;
                //this.departamentosActividadLoading=false; 
            });
        
        },


        //Obtener municipios por departamento
        async obtenerMunicipiosPorDepartamento(departamentosId){
            this.municipios = [];
            this.municipiosLoading = true;
            this.municipioSeleccionado = 0;
           

            await this.$store
            .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, departamentosId) //1 representa al país 1 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.municipios = res.data;
                    //this.setLatLong(this.departamentoSeleccionadoMonto.latitud, this.departamentoSeleccionadoMonto.longitud);
                }
                this.municipiosLoading=false;
            })
            .catch(() => {
                this.municipios = [];
                this.municipiosLoading=false;
            });
        },


        //Obtener entidades o instituciones
        async obtenerEntidades() {
            this.instituciones = [];
            this.ddInstitucionesLoading = true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
            .then(res => {
            if (res.status === 200) {
                this.instituciones = res.data;
            }
            this.ddInstitucionesLoading = false;
            })
            .catch(() => {
                this.instituciones = [];
                this.ddInstitucionesLoading = false;
            });
        },

        //Obtener las unidades ejecutoras
        async obtenerUnidadesEjecutorasPorEntidad(entidadId) {

    
            this.unidadesEjecutorasLoading = true;
            this.unidadesEjecutoras = [];
        

            await this.$store
            .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, entidadId)
            .then(res => {
                if(res.status===200){
                    this.unidadesEjecutoras = this.$store.state.unidadejecutora.unidadesEjecutoras;
                }
                
                this.unidadesEjecutorasLoading = false;
            })
            .catch(() => {
                this.unidadesEjecutorasLoading = false;
                this.unidadesEjecutoras = [];
            });
        },

        //Sectores beneficiados
        async obtenerSectoresBeneficiados() {
            this.sectoresBeneficiadosLoading=true;
            this.sectoresBeneficiados = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Sectores/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.sectoresBeneficiados = res.data;

                }
                this.sectoresBeneficiadosLoading=false;
            })
            .catch(() => {
                this.sectoresBeneficiados = [];
                this.sectoresBeneficiadosLoading=false;
            });
        
        },

        //Tipos de cooperacion
        async obtenerTiposCooperacion() {
            this.tiposCooperacion = [];
            this.ddTiposCooperacionLoading=true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposCooperacion/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.tiposCooperacion = res.data;
                }
                this.ddTiposCooperacionLoading=false;
            })
            .catch(() => {
                this.ddTiposCooperacionLoading=false;
                this.tiposCooperacion = [];
            });
        },

        //Obtener los tipos de fuente cooperante
        async obtenerTiposFuenteCooperante() {
            this.tiposFuenteCooperante = [];
            this.ddTipoFuenteCooperanteLoading=true;
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposFuenteCooperante/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.tiposFuenteCooperante = res.data;
                    }
                    this.ddTipoFuenteCooperanteLoading=false;
                })
                .catch(() => {
                    this.ddTipoFuenteCooperanteLoading=false;
                    this.tiposFuenteCooperante = [];
                });
        },



        async obtenerFuentesBilaterales() {
            this.fuentesBilaterales = [];
            this.ddFuentesBilateralesLoading=true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesBilaterales/all/1/0'})
            .then(res => {
                if (res.status === 200) {
                    this.fuentesBilaterales = res.data;
                }
                this.ddFuentesBilateralesLoading=false;
            })
            .catch(() => {
                this.ddFuentesBilateralesLoading=false;
                this.fuentesBilaterales = [];
            });
        },

        async obtenerAgenciasPorFuenteBilateral(fuenteBilateralId) {
            this.agencias = [];
            this.ddAgenciasLoading=true;
            await this.$store
            .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Agencias/all/1', id: fuenteBilateralId})
            .then(res => {
                if (res.status === 200) {
                    this.ddAgenciasLoading=false;
                    this.agencias = res.data;
                }
            })
            .catch(() => {
                this.ddAgenciasLoading=false;
                this.agencias = [];
            });
        },

        async obtenerFuentesMultilaterales() {
            this.fuentesMultilaterales = [];
            this.ddFuentesMultilateralesLoading=false;
            await this.$store.dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuenteMultiLateral/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.fuentesMultilaterales = res.data;
                    }
                    this.ddFuentesMultilateralesLoading=false;
                })
                .catch(() => {
                    this.ddFuentesMultilateralesLoading=false;
                    this.fuentesMultilaterales = [];
                });
        },

        // Obtener agencias Multilaterales
        async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
            this.agenciasMultilaterales = [];
            this.agenciasMultilateralesLoading = true;

            await this.$store
            .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {estadoId:1, fuentesMultilateralesId: fuenteMultilateralId})
            .then(res => {
                if(res.status===200){

                    this.agenciasMultilaterales = res.data;
                }
            this.agenciasMultilateralesLoading = false;
            })
            .catch(() => {
                //console.log(error)
                this.agenciasMultilateralesLoading = false;
            });
        },


        //obtener las PNDS
        async obtenerPnds() {
            this.ddPndLoading=true;
            this.pnds = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'PND/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.pnds = res.data;

                }
                this.ddPndLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddPndLoading=false;
            });
        
        },

         //Obtener las ods
        async obtenerOds(){
             this.ddOdsLoading=true;
            this.ods = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ODS/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.ods = res.data;

                }
                this.ddOdsLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddOdsLoading=false;
            });
        },

         //Obtener pgg
        async obtenerPggs(){
            this.pggs = [];
            this.pggSeleccionada=0;
            this.ddPggLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PGG/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.pggs = res.data;
                }
                this.ddPggLoading=false;
            })
            .catch(() => {
                this.pggs = [];
                this.ddPggLoading=false;
            });
        },

        //Validar el estado del proyecto en el change del dropdown
        validarEstadoProyecto(){
          //  console.log(this.estadoSeleccionado)  
        },



        //Eliminar todoos los filtros seleccionados
        eliminarFiltros(){
            this.modalidadCooperacionSeleccionada=0;
            this.estadoSeleccionado=0;
           // this.tipoCooperacionSeleccionada=0;
            this.departamentoSeleccionado=0;
            this.palabraClave = "";
            this.municipioSeleccionado=0;
            this.entidadSeleccionada=0;
            this.unidadEjecutoraSeleccionada=0;
            this.sectorBeneficiadoSeleccionado=0;
            this.tipoCooperacionSeleccionada=0;
            this.pndSeleccionado=0;
            this.odsSeleccionado=0;
            this.tipoFuenteCooperanteSeleccionada=0;
            this.fuenteBilateralSeleccionada=0;
            this.agenciaSeleccionada=0;
            this.fuenteMultilateralSeleccionada=0;
            this.agenciaMultilateralSeleccionada=0;
            this.pggSeleccionada=0;
            this.noFilter= true;
        },

        definirFiltro(){
            if(
                (this.modalidadCooperacionSeleccionada  != null && this.modalidadCooperacionSeleccionada != 0) ||
                (this.estadoSeleccionado && this.estadoSeleccionado!=0) || 
                (this.palabraClave && this.palabraClave.length > 0) || 
                (this.departamentoSeleccionado && this.departamentoSeleccionado != 0) ||
                (this.municipioSeleccionado && this.municipioSeleccionado != 0) ||
                (this.entidadSeleccionada && this.entidadSeleccionada != 0) ||
                (this.unidadEjecutoraSeleccionada && this.unidadEjecutoraSeleccionada != 0) ||
                (this.sectorBeneficiadoSeleccionado && this.sectorBeneficiadoSeleccionado != 0) ||
                (this.tipoCooperacionSeleccionada && this.tipoCooperacionSeleccionada != 0) ||
                (this.tipoFuenteCooperanteSeleccionada && this.tipoFuenteCooperanteSeleccionada != 0) ||
                (this.fuenteBilateralSeleccionada && this.fuenteBilateralSeleccionada != 0) ||
                (this.agenciaSeleccionada && this.agenciaSeleccionada != 0) ||
                (this.fuenteMultilateralSeleccionada && this.fuenteMultilateralSeleccionada != 0) ||
                (this.agenciaMultilateralSeleccionada && this.agenciaMultilateralSeleccionada != 0) 
            ){
                this.noFilter = false;
            }
            console.log(this.modalidadCooperacionSeleccionada)
        },


        //Obtener listado de proyectos con sus maracadores
        async obtenerProyectos(){
            this.infoWindowPosition= null;
            this.infoWindowOpen=false;
            this.cantProyectosEncontrados=0;
            this.marcadores = [];
            let filtros= {
                modalidadCooperacionId: this.modalidadCooperacionSeleccionada,
                estadoId: this.estadoSeleccionado,
                palabraClave: this.palabraClave,
               // tipoCooperacionId: this.tipoCooperacionSeleccionada,
                departamentoId: this.departamentoSeleccionado,
                municipioId: this.municipioSeleccionado,
                entidadId: this.entidadSeleccionada,
                unidadEjecutoraId: this.unidadEjecutoraSeleccionada,
                sectorBeneficiadoId: this.sectorBeneficiadoSeleccionado,
                tipoCooperacionId: this.tipoCooperacionSeleccionada,
                pndId: this.pndSeleccionado,
                odsId: this.odsSeleccionado,
                tipoFuenteCooperanteId: this.tipoFuenteCooperanteSeleccionada,
                fuenteBilateralId: this.fuenteBilateralSeleccionada,
                agenciaBilateralId: this.agenciaSeleccionada,
                fuenteMultilateralId: this.fuenteMultilateralSeleccionada,
                agenciaMultilateralId: this.agenciaMultilateralSeleccionada,
                pggId: this.pggSeleccionada,
                sinFiltros: this.noFilter ? 1 : 0
            };
            
            this.btnBuscarProyectosLoading=true;
            this.tableLoading=true;
            this.proyectos = [];

            //this.ddPeriodosLoading=true;
            
            await this.$store
            .dispatch(OBTENER_PROYECTOS_GEORREFERENCIACION, filtros)
            .then(res => {
                if (res.status === 200) {
                    this.proyectos = res.data;
                    this.cantProyectosEncontrados=this.proyectos.length;
                    this.busqueda = true;
                    this.setAllMarcadores();
                } else {
                    this.busqueda = false;
                }
                this.tableLoading=false;
                this.btnBuscarProyectosLoading=false;
            })
            .catch(() => {
                this.proyectos = [];
                this.btnBuscarProyectosLoading=false;
                this.tableLoading=false;
            });

        },


        //Obtener todos los marcadores de todos los proyectos
        async obtenerMarcadores(){
             this.btnBusquedaLoading=true;
            this.tableLoading=true;
            this.marcadoresProyecto = [];

            //this.ddPeriodosLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ReporteGeorreferenciacion/all/Marcadores`})
            .then(res => {
                if (res.status === 200) {
                    this.marcadoresProyecto = res.data;
                    this.busqueda = true;
                } else {
                    this.busqueda = false;
                }
                this.tableLoading=false;
                this.btnBusquedaLoading=false;
            })
            .catch(() => {
                this.marcadoresProyecto = [];
                this.btnBusquedaLoading=false;
                this.tableLoading=false;
            });
        },

        //Asignar los marcadores de proyecto(s) seleccionado(s)
        setMarcadores(datosProyecto, sMarcadores){

            this.infoWindowPosition= null;
            this.infoWindowOpen=false;

            this.mostrarMapaLoading=true;
           
            //console.log(marcadores)

            this.marcadores = [];

            sMarcadores.forEach((element, index) => {
                //console.log(element)
                if(element.latitud && element.longitud) {
                    this.marcadores.push(
                        {
                            position: {
                                lat: parseFloat(element.latitud), 
                                lng: parseFloat(element.longitud), 
                                monto: parseFloat(element.monto), 
                                codigoProyecto: element.codigoProyecto, 
                                nombreProyecto: element.nombreProyecto,
                                situacionActual: element.estadoProyecto,
                                tipoCooperacionId: element.tipoCooperacionId,
                                tipoCooperacion: element.tipoCooperacion,
                                fuenteCooperante: element.fuenteCooperante,
                                agencia: datosProyecto.agencia,
                                institucion: datosProyecto.institucion,
                                sector: element.sector,
                                anioInicio: element.anioInicio,
                                anioFinalizacion: element.anioFinalizacion,
                                montoSuscrito: datosProyecto.montoSuscrito,
                                unidadEjecutora: datosProyecto.unidadEjecutora
                            }
                        });
                }    

                this.infoWindow.open[index] = false;
            });
            this.mostrarMapaLoading=false;
            this.centerMap= { lat: 16.062775, lng: -89.3520961 };

        },

        //Asignar todos los marcadores de todos los proyectos (al seleccionar todos)
        setAllMarcadores(){
            this.infoWindowPosition= null;
            this.infoWindowOpen=false;
            this.mostrarMapaLoading=true;
            this.marcadores = [];
            let idx = 0;
            this.proyectos.forEach(element => {
                element.marcadoresDoc.forEach((marcador) => {
                    //console.log(marcador);
                   
                    //console.log("index: " + index + "  ---- contador: "+ i)
                    
                    this.marcadores.push(
                        {
                            position: {
                                lat: parseFloat(marcador.latitud), 
                                lng: parseFloat(marcador.longitud), 
                                monto: parseFloat(marcador.monto), 
                                codigoProyecto: marcador.codigoProyecto, 
                                nombreProyecto: marcador.nombreProyecto,
                                situacionActual: marcador.estadoProyecto,
                                tipoCooperacionId: marcador.tipoCooperacionId,
                                tipoCooperacion: marcador.tipoCooperacion,
                                fuenteCooperante: marcador.fuenteCooperante,
                                agencia: element.agencia,
                                institucion: element.institucion,
                                sector: marcador.sector,
                                anioInicio: marcador.anioInicio,
                                anioFinalizacion: marcador.anioFinalizacion,
                                montoSuscrito: element.montoSuscrito,
                                unidadEjecutora: element.unidadEjecutora
                            }
                        });
                    this.infoWindow.open[idx] = false;
                    idx++;
                    
                })
            });

            //console.log(this.marcadores);

             this.centerMap= { lat: 16.062775, lng: -89.3520961 };
             this.mostrarMapaLoading=false;
        },

        //Mostrar ventana de información del marcador
        openGmapInfo(index, marcador) {
            ///Nuevo
            this.infoWindowPosition={ lat: marcador.position.lat, lng: marcador.position.lng };

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == index) {
              this.infoWindowOpen = !this.infoWindowOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWindowOpen = true;
              this.currentMidx = index;

            }

        


            ///ENd nuevo
         

         /*  if(this.infoWindow.open && this.infoWindow.open.length > 0){
            //console.log("tiene datos" + this.infoWindow.open);

            this.infoWindow.open.forEach((element, idx) => {
                this.infoWindow.open[idx] = false;
            });

           

           }
 */
            const {
                lat,
                lng,
                monto,
                codigoProyecto,
                nombreProyecto,
                situacionActual,
                tipoCooperacion,
                fuenteCooperante,
                sector,
                anioInicio,
                anioFinalizacion,
                agencia,
                institucion,
                unidadEjecutora,
                montoSuscrito
            } = this.marcadores[index].position;
            

            //console.log(this.marcadores[index])

            this.infoWindow.position = { lat: lat, lng: lng };
               
            this.infoWindow.template = `
            <div class="container" style="letter-spacing: 0.1px; width:100%; font-size: 13px;">
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Código Sicoopera:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${codigoProyecto}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Nombre:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${nombreProyecto}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-6 col-sm-6 col-xs-12 pt-1 pb-1">
                        <b>Año inicio:</b>
                    </div> <!--end:: col-->
                     <div class="col col-md-6 col-sm-6 col-xs-12 pt-1 pb-1">
                        <b>Año finalización:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-6 col-sm-6 col-xs-12 pt-0 pb-3">
                        ${anioInicio}
                    </div> <!--end:: col-->
                    <div class="col col-md-6 col-sm-6 col-xs-12 pt-0 pb-3">
                        ${anioFinalizacion}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Tipo de cooperación:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${tipoCooperacion}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Institución:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${institucion ? institucion : "N/A"}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Unidad Ejecutora:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${unidadEjecutora ? unidadEjecutora : "N/A"}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Fuente cooperante:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${fuenteCooperante}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Agencia:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${agencia ? agencia : "N/A"}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Sector beneficiado:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${sector ? sector : "N/A"}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Situación actual:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        ${situacionActual}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Monto total suscrito:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        GTQ ${montoSuscrito ? parseFloat(montoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) : "N/A"}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Monto suscrito del departamento:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        GTQ ${monto ? parseFloat(monto).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) : "N/A"}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
            </div> <!-- end:: container-->`;

            this.infoOptions.content=this.infoWindow.template;
                /*
            this.infoWindow.open[index] = true;
            */

        } ,

        /*closeInfoWindow(indx){
          
            //this.infoWindow.open[indx] = false;
            
            this.infoWindow.open.forEach((element, idx) => {
                this.infoWindow.open[idx] = false;
            });

         
        },*/
    },

    created(){
        this.obtenerModalidadesCooperacion();
        this.obtenerEstadosProyecto();
        this.obtenerDepartamentos();
        this.obtenerEntidades();
        this.obtenerSectoresBeneficiados();
        this.obtenerTiposCooperacion();
        this.obtenerTiposFuenteCooperante();
        this.obtenerFuentesBilaterales();
        this.obtenerFuentesMultilaterales();
        this.obtenerPnds();
        this.obtenerOds();
        this.obtenerPggs();
        this.obtenerProyectos().then(()=>{
            this.setAllMarcadores();
        });
        // this.obtenerMarcadores();
    }
}
</script>
<style lang="scss">
    
</style>