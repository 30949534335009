var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Georreferenciación de Proyectos de Cooperación para el Desarrollo ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[(_vm.mostrarMapaLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article"}})],1)],1)],1):_vm._e(),(!_vm.mostrarMapaLoading)?_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"800px"},attrs:{"center":_vm.centerMap,"zoom":7,"id":"map"}},[_vm._l((_vm.marcadores),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":m.position.tipoCooperacionId===1 ? _vm.iconoCFNR : m.position.tipoCooperacionId===2 ? _vm.iconoCTNR : _vm.iconoCENR},on:{"click":function($event){_vm.center = m.position;
                               _vm.openGmapInfo(index, m);}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPosition,"opened":_vm.infoWindowOpen},on:{"closeclick":function($event){_vm.infoWindowOpen=false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoWindow.template)}})])],2):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Filtros de búsqueda "),_c('v-btn',{staticClass:"white--text mb-2 btn-sm  float-right",attrs:{"small":"","color":"red lighten-1"},on:{"click":function($event){return _vm.eliminarFiltros();}}},[_c('v-icon',[_vm._v("mdi-filter-minus-outline")]),_vm._v(" Eliminar filtros ")],1)],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.modalidadesCooperacion,"loading":false,"dense":"","clearable":true,"filled":"","label":"Modalidad de cooperación","item-text":"modalidad","item-value":"id","no-data-text":_vm.modalidadesCooperacion != null
                                           ? 'Seleccione una modalidad de cooperación'
                                           : 'No se han encontrado modalidades de cooperación',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.modalidadCooperacionSeleccionada),callback:function ($$v) {_vm.modalidadCooperacionSeleccionada=$$v},expression:"modalidadCooperacionSeleccionada"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.esatadosProyectos,"loading":_vm.ddEstadosProyectos,"dense":"","clearable":true,"filled":"","label":"Estado del proyecto","item-text":"nombreEstado","item-value":"id","no-data-text":_vm.esatadosProyectos != null
                                           ? 'Seleccione un estado para los proyectos'
                                           : 'No se han encontrado estados de proyectos',"menu-props":"auto"},on:{"change":function($event){_vm.validarEstadoProyecto; _vm.definirFiltro()}},model:{value:(_vm.estadoSeleccionado),callback:function ($$v) {_vm.estadoSeleccionado=$$v},expression:"estadoSeleccionado"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"color":"blue-grey lighten-2","autocomplete":"off","dense":"","filled":"","label":"Nombre del proyecto, código Sicoopera","maxlength":"50"},on:{"input":function($event){return _vm.definirFiltro()}},model:{value:(_vm.palabraClave),callback:function ($$v) {_vm.palabraClave=$$v},expression:"palabraClave"}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.departamentos,"loading":_vm.departamentosLoading,"dense":"","filled":"","label":"Departamento","item-text":"departamento","item-value":"id","no-data-text":_vm.departamentos != null
                                           ? 'Seleccione un departamento'
                                           : 'No se han encontrado departamentos',"menu-props":"auto"},on:{"change":function($event){_vm.obtenerMunicipiosPorDepartamento(_vm.departamentoSeleccionado); _vm.definirFiltro()}},model:{value:(_vm.departamentoSeleccionado),callback:function ($$v) {_vm.departamentoSeleccionado=$$v},expression:"departamentoSeleccionado"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.municipios,"loading":_vm.municipiosLoading,"dense":"","filled":"","label":"Municipio","item-text":"municipio","item-value":"id","no-data-text":_vm.municipios != null
                                           ? 'Seleccione un municipio'
                                           : 'No se han encontrado municipios',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.municipioSeleccionado),callback:function ($$v) {_vm.municipioSeleccionado=$$v},expression:"municipioSeleccionado"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.instituciones,"dense":"","filled":"","loading":_vm.ddInstitucionesLoading,"label":"Institución","item-text":function (item) { return item.sigla ? ((item.nombreInstitucion) + " (" + (item.sigla) + ")") : item.nombreInstitucion; },"item-value":"id"},on:{"change":function($event){_vm.obtenerUnidadesEjecutorasPorEntidad(_vm.entidadSeleccionada); _vm.definirFiltro()}},model:{value:(_vm.entidadSeleccionada),callback:function ($$v) {_vm.entidadSeleccionada=$$v},expression:"entidadSeleccionada"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.unidadesEjecutoras,"dense":"","filled":"","loading":_vm.unidadesEjecutorasLoading,"label":"Unidad ejecutora","item-text":"unidadEjecutora","item-value":"id"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.unidadEjecutoraSeleccionada),callback:function ($$v) {_vm.unidadEjecutoraSeleccionada=$$v},expression:"unidadEjecutoraSeleccionada"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.sectoresBeneficiados,"loading":_vm.sectoresBeneficiadosLoading,"dense":"","filled":"","label":"Sector beneficiado","item-text":"sector","item-value":"id","no-data-text":_vm.sectoresBeneficiados > 0
                                           ? 'Seleccione un sector beneficiado'
                                           : 'No se han encontrado sectores beneficiados',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.sectorBeneficiadoSeleccionado),callback:function ($$v) {_vm.sectorBeneficiadoSeleccionado=$$v},expression:"sectorBeneficiadoSeleccionado"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.tiposCooperacion,"loading":false,"dense":"","filled":"","label":"Tipo de cooperación","item-text":"nombreTipoCooperacion","item-value":"id","no-data-text":_vm.tiposCooperacion != null
                                           ? 'Seleccione un tipo de cooperación'
                                           : 'No se han encontrado tipos de cooperación',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.tipoCooperacionSeleccionada),callback:function ($$v) {_vm.tipoCooperacionSeleccionada=$$v},expression:"tipoCooperacionSeleccionada"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.pnds,"loading":_vm.ddPndLoading,"dense":"","filled":"","label":"Prioridad Nacional de Desarrollo","item-text":"nombrePND","item-value":"id","no-data-text":_vm.pnds != null
                                           ? 'Seleccione Prioridad Nacional de Desarrollo'
                                           : 'No se han encontrado Prioridades Nacionales de Desarrollo',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.pndSeleccionado),callback:function ($$v) {_vm.pndSeleccionado=$$v},expression:"pndSeleccionado"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.ods,"loading":_vm.ddOdsLoading,"dense":"","filled":"","label":"Objetivo de Desarrollo Sostenible","item-text":"nombreODS","item-value":"id","no-data-text":_vm.pnds != null
                                           ? 'Seleccione Objetivo de Desarrollo Sostenible'
                                           : 'No se han encontrado Objetivos de Desarrollo Sostenible',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.odsSeleccionado),callback:function ($$v) {_vm.odsSeleccionado=$$v},expression:"odsSeleccionado"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.tiposFuenteCooperante,"loading":_vm.ddTipoFuenteCooperanteLoading,"dense":"","filled":"","label":"Tipo de fuente cooperante","item-text":"nombreTipoFuente","item-value":"id","no-data-text":_vm.tiposFuenteCooperante != null
                                           ? 'Selecciona un tipo de fuente cooperante'
                                           : 'No se han encontrado tipos de fuente cooperante',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.tipoFuenteCooperanteSeleccionada),callback:function ($$v) {_vm.tipoFuenteCooperanteSeleccionada=$$v},expression:"tipoFuenteCooperanteSeleccionada"}})],1)],1),_c('v-row',[(_vm.tipoFuenteCooperanteSeleccionada == 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.fuentesBilaterales,"loading":_vm.ddFuentesBilateralesLoading,"required":_vm.tipoFuenteCooperanteSeleccionada == 1,"dense":"","filled":"","label":"País Fuente Bilateral","item-text":"pais","item-value":"id","no-data-text":_vm.fuentesBilaterales != null
                                           ? 'Selecciona un país'
                                           : 'No se han encontrado países',"menu-props":"auto"},on:{"change":function($event){_vm.obtenerAgenciasPorFuenteBilateral(_vm.fuenteBilateralSeleccionada); _vm.agenciaSeleccionada={}; _vm.definirFiltro()}},model:{value:(_vm.fuenteBilateralSeleccionada),callback:function ($$v) {_vm.fuenteBilateralSeleccionada=$$v},expression:"fuenteBilateralSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteCooperanteSeleccionada == 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.agencias,"loading":_vm.ddAgenciasLoading,"clearable":true,"dense":"","filled":"","label":"Seleccione Agencia","item-text":"nombreAgencia","item-value":"id","no-data-text":_vm.agencias > 0
                                           ? 'Selecciona una agencia'
                                           : 'No se han encontrado agencias',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.agenciaSeleccionada),callback:function ($$v) {_vm.agenciaSeleccionada=$$v},expression:"agenciaSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteCooperanteSeleccionada == 2)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.fuentesMultilaterales,"loading":_vm.ddFuentesMultilateralesLoading,"dense":"","filled":"","label":"Seleccione fuente multilateral","item-text":"nombreFuenteMultilateral","item-value":"id","no-data-text":_vm.fuentesMultilaterales > 0
                                           ? 'Seleccione una fuente multilateral'
                                           : 'No se han encontrado fuentes multilaterales',"menu-props":"auto"},on:{"change":function($event){_vm.obtenerAgenciasMultilaterales(_vm.fuenteMultilateralSeleccionada); _vm.agenciaMultilateralSeleccionada={}; _vm.definirFiltro()}},model:{value:(_vm.fuenteMultilateralSeleccionada),callback:function ($$v) {_vm.fuenteMultilateralSeleccionada=$$v},expression:"fuenteMultilateralSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteCooperanteSeleccionada == 2)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.agenciasMultilaterales,"clearable":true,"loading":_vm.agenciasMultilateralesLoading,"dense":"","filled":"","label":"Seleccione Agencia Multilateral","item-text":"agencia","item-value":"id","return-object":"","no-data-text":_vm.agenciasMultilaterales > 0
                                           ? 'Selecciona una agencia'
                                           : 'No se han encontrado agencias para la fuente seleccionada',"menu-props":"auto"},on:{"change":function($event){return _vm.definirFiltro()}},model:{value:(_vm.agenciaMultilateralSeleccionada),callback:function ($$v) {_vm.agenciaMultilateralSeleccionada=$$v},expression:"agenciaMultilateralSeleccionada"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"loading":_vm.btnBuscarProyectosLoading,"disabled":_vm.btnBuscarProyectosLoading},on:{"click":function($event){return _vm.obtenerProyectos();}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v("Buscar proyectos ")],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"mb-8",attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"100%"}},[_c('v-toolbar',{attrs:{"color":"light-blue-502","dark":""}},[_c('v-toolbar-title',[_vm._v("Proyectos encontrados "+_vm._s(_vm.cantProyectosEncontrados))]),_c('v-spacer')],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}}),(_vm.btnBuscarProyectosLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article"}})],1)],1)],1):_vm._e(),(!_vm.btnBuscarProyectosLoading)?_c('v-list',{staticStyle:{"height":"400px","overflow-y":"auto"},attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"blue--text"}},[(_vm.proyectos.length > 1)?_c('v-list-item',{on:{"click":function($event){return _vm.setAllMarcadores()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                           var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("Todos los proyectos")]),_c('v-list-item-subtitle',{staticClass:"text-subtitle-1"},[_vm._v("Visualizar todos los proyectos filtrados")])],1)]}}],null,false,1674489137)}):_vm._e(),_c('v-divider'),_vm._l((_vm.proyectos),function(item,index){return [_c('v-list-item',{key:item.codigoProyecto,on:{"click":function($event){return _vm.setMarcadores(item, item.marcadoresDoc)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                           var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6",domProps:{"textContent":_vm._s(item.codigo)}}),_c('v-list-item-subtitle',{staticClass:"text-subtitle-1",domProps:{"textContent":_vm._s(item.nombreProyecto)}})],1)]}}],null,true)}),(index < _vm.proyectos.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }